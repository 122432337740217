import React from "react";
import "./TracesTimelineMobileContainer.scss";
import { observer } from "mobx-react-lite";
import PanelHeader from "../../../../global/molecules/PanelHeader/PanelHeader";
import { useTraceStore } from "../../../../../contexts/traces.context";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ITraceOutletContext } from "../../../../../models/TraceModel";
import TracesTimeline from "../../../organisms/Traces/TracesTimeline/TracesTimeline";
import { DATE_DISPLAY_FORMAT, PATH } from "../../../../../common/constants";
import { sendMonitoringLogs } from "../../../../../common/utils";
import CustomDatePicker from "../../../molecules/CustomDatePicker/CustomDatePicker";
import moment from "moment";

const TracesTimelineMobileContainer = () => {
  const traceStore = useTraceStore();
  const navigate = useNavigate();
  const context = useOutletContext<ITraceOutletContext>();

  const title = traceStore?.vehiclesList?.find((v) => v.id == context?.terminalId)?.title;
  const handleOnBackClick = () => {
    sendMonitoringLogs("HISTORY_TIMELINE_BACK");
    const date = traceStore?.traceFilter?.selectedDate;
    const path = `${PATH.TRACES}/${context?.terminalId}/${date?.format(DATE_DISPLAY_FORMAT)}`;
    context?.onResetTraces();
    navigate(path);
  };

  const handleOnDateSelect = (date: Date | null) => {
    try {
      sendMonitoringLogs("HISTORY_FILTER_DATE_SELECT");
      date &&
        navigate(
          `${PATH.TRACES}/${context.terminalId}/${moment(date).format(
            DATE_DISPLAY_FORMAT,
          )}/timeline`,
        );
    } catch (error) {
      console.log("TracesMapMobileFooter ~ handleOnDateSelect ~ error:", error);
    }
  };

  return (
    <div className="traces-timeline-container-mob">
      <PanelHeader
        title={title}
        onBackClick={handleOnBackClick}
        actionBtns={
          <CustomDatePicker
            selectedDate={context?.traceFilter?.selectedDate}
            onDateSelect={handleOnDateSelect}
          />
        }
      />
      <TracesTimeline />
    </div>
  );
};

export default observer(TracesTimelineMobileContainer);
