import React, { useEffect, useRef, useState } from "react";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";
import TaskView from "../../molecules/TaskView/TaskView";
import TripView from "../../molecules/TripView/TripView";
import VehicleCrew from "../../molecules/Crew/VehicleCrew";
import Address from "../../domain/Vehicle/molecules/Address/Address";
import Accordion from "../../molecules/Accordion/Accordion";
import VehicleService from "../../../services/Vehicle.service";
import { ERROR_CANCELED, MEASUREMENT_UNITS, STATE, TOAST_STYLE, VEHICLE_REEFER_STATUS } from "../../../common/constants";
import { IVehicleData, IVehicleTripTask, IZone } from "../../../models/VehicleModel";
import { ITask, IRoute, ITrip } from "../../../models/VehicleDetailsModel";
import { formatZones, getAppConfig, getTranslatedValue, scrollToElement } from "../../../common/utils";
import ProgressBar from "../../molecules/ProgressBar/ProgressBar";
import { handleError } from "../../../common/helpers/ErrorHandler";
import ToastWrapper, {
  defaultToastObj,
  IToast,
} from "../../application/molecules/ToastWrapper/ToastWrapper";
import speed from "../../../assets/images/speed.svg";
import mileage from "../../../assets/images/mileage.svg";
import weight from "../../../assets/images/weight.svg";
import thermostat from "../../../assets/images/thermostat.svg";
import locationPin from "../../../assets/images/location_pin.svg";
import fuel from "../../../assets/images/fuel_tank.svg";
import { ReactComponent as Reefer } from "../../../assets/images/reefer.svg";
import "./VehicleDetails.scss";
import { observer } from "mobx-react-lite";
import { ISearchResult } from "../SearchView/SearchViewModel";
import TachoStatus from "../../molecules/TachoStatus/TachoStatus";
import useMobileDetect from "../../../hooks/useMobileDetect/useMobileDetect";
import { useParams } from "react-router-dom";
import { useDidMountEffect } from "../../../hooks/useDidMountEffect/useDidMountEffect";
import VehicleZones, { ViewMode } from "../../domain/Vehicle/molecules/VehicleZones/VehicleZones";
import TrailerDetails from "../../domain/Vehicle/organisms/TrailerDetails/TrailerDetails";
import { OverlayTrigger, Tooltip } from "@trimbleinc/modus-react-bootstrap";
import DateTime from "../../molecules/DateTime/DateTime";

export interface IProps {
  lastUpdatedTime?: number;
  searchedLocation?: ISearchResult;
  selectedTask?: ITask | null;
  selectedRoute?: IRoute | null;
  selectedVehicle?: IVehicleData | null;
  setSelectedRoute?: (route?: IRoute | null | undefined) => void;
  actionName?: string;
}

const VehicleDetailsView = (props: IProps) => {
  const { lastUpdatedTime, selectedTask, selectedRoute, selectedVehicle, setSelectedRoute } = props;
  const controller = useRef<AbortController>();
  const [isLoading, setLoading] = useState(true);
  const [vehicleDetailsError, setVehicleDetailsError] = useState("");
  const [vehicleTripTask, setVehicleTripTask] = useState<IVehicleTripTask>();
  // * states to handle toast.
  const [detailsToast, setDetailsToast] = useState<IToast>(defaultToastObj);
  const [currentVehicle, setCurrentVehicle] = useState<IVehicleData | null>(null);
  const [activeTripTaskId, setActiveTripTaskId] = useState<string>();
  const [activeDescriptionIds, setActiveDescriptionIds] = useState<string[]>([]);
  const [status, setStatus] = useState<number>();
  const [isVehicleDataLoaded, setIsVehicleDataLoaded] = useState<boolean>(false);
  const [openTaskId, setOpenTaskId] = useState<string>();
  const vehicleService = new VehicleService();
  const params = useParams();
  const isMobile = useMobileDetect();
  const separator = ":";
  const setTaskStatus = (data: IVehicleTripTask) => {
    if (data.tasks && data.tasks.length > 0) {
      const isAllTasksFinished = data.tasks.every((item) => item.status === STATE.FINISHED);
      const busyTask = data.tasks.some((item) => item.status === STATE.BUSY);
      if (isAllTasksFinished) {
        setStatus(STATE.FINISHED);
      } else if (busyTask) {
        setStatus(STATE.BUSY);
      } else {
        setStatus(STATE.NEW);
      }
    }
  };
  const setFirstOpenTask = (tasks: ITask[]) => {
    const openTaskId = tasks?.find((t: ITask) => t.status !== STATE.FINISHED)?.id;
    setOpenTaskId(openTaskId);
  };

  /**
   * This method is responsible for setting the active trip or task for a vehicle.
   * At first it searches for a 'busy' trip or task.
   * If a 'busy' trip or task is found, its Id is set as the active trip/task Id.
   * If no 'busy' trip or task is found, it then searches for an 'accepted' trip or task and sets its Id as the active trip/task Id.
   * Once an active trip/task Id is found, then it tries to get the tasks from a 'busy' trip.
   * If no 'busy' trip is found, it then tries to get the tasks from an 'accepted' trip.
   * If neither a 'busy' nor an 'accepted' trip is found, by default it gets the tasks list from the vehicle data.
   * Once the tasks are obtained, it calls the `setFirstOpenTask` function which is responsible for scrolling to the task that is currently open in the tasks list.
   */
  const setActiveTripTask = (data: IVehicleTripTask) => {
    try {
      if (!isVehicleDataLoaded) {
        setActiveTripTaskId("");
        setOpenTaskId("");
        const busyTrip = data?.trips?.find((t: ITrip) => t.status === STATE.BUSY);
        const busyTask = data?.tasks?.find((t: ITask) => t.status === STATE.BUSY);
        let id = busyTrip ? busyTrip.id : busyTask?.id;
        let acceptedTrip: ITrip | undefined, acceptedTask: ITask | undefined;
        if (!id) {
          acceptedTrip = data?.trips?.find((t: ITrip) => t.status === STATE.ACCEPTED);
          acceptedTask = data?.tasks?.find((t: ITask) => t.status === STATE.ACCEPTED);
          id = acceptedTrip ? acceptedTrip.id : acceptedTask?.id;
        }
        if (id) {
          setActiveTripTaskId(id);
          const tasks = busyTrip?.tasks || acceptedTrip?.tasks || data?.tasks;
          setFirstOpenTask(tasks);
        }
        setIsVehicleDataLoaded(true);
      }
    } catch (error) {
      console.log("setActiveTripTask ~ error", error);
    }
  };

  const toggleTaskDescription = (id: string) => {
    if (activeDescriptionIds.includes(id)) {
      setActiveDescriptionIds([...activeDescriptionIds.filter((existValue) => existValue !== id)]);
    } else {
      setActiveDescriptionIds([...activeDescriptionIds, id]);
    }
  };

  const loadVehicleDetails = async () => {
    if (selectedVehicle?.id && !selectedVehicle?.trailerId) {
      if (getAppConfig().loadFromAPI === true) {
        controller?.current?.abort();
        controller.current = new AbortController();
        vehicleService
          .getDetails(selectedVehicle?.id, controller.current)
          .then((result: any) => {
            if (result && result.data) {
              setVehicleTripTask(result?.data as IVehicleTripTask);
              setActiveTripTask(result.data);
              setTaskStatus(result.data);
              setLoading(false);
            }
          })
          .catch((e) => {
            if (e && e?.code !== ERROR_CANCELED) {
              const errMsg = handleError(e);
              errMsg && setVehicleDetailsError(errMsg);
              setDetailsToast({
                showToast: true,
                message: `${i18nInstance.t("TTM.followup.error.internalError")}`,
                style: TOAST_STYLE.ERROR,
              });
            }
            setLoading(false);
          });
      } else {
        setVehicleTripTask({
          trips: selectedVehicle?.trips,
          tasks: selectedVehicle?.tasks,
          id: selectedVehicle?.id,
        });
        setLoading(false);
      }
    } else {
      setLoading(false);
      setVehicleTripTask({
        trips: [],
        tasks: [],
        id: selectedVehicle?.id || "",
      });
    }
  };

  useEffect(() => {
    if (selectedVehicle && selectedVehicle?.id !== currentVehicle?.id) {
      setCurrentVehicle(selectedVehicle);
      setActiveDescriptionIds([]);
      setLoading(true);
      setVehicleTripTask(undefined);
      setVehicleDetailsError("");
      loadVehicleDetails();
    }
  }, [selectedVehicle]);

  useEffect(() => {
    if (params.vehicleId?.toString() !== selectedVehicle?.id?.toString()) {
      setIsVehicleDataLoaded(false);
    }
  }, [params.vehicleId]);
  useDidMountEffect(() => {
    openTaskId && scrollToElement(openTaskId);
  }, [openTaskId]);
  useEffect(() => {
    try {
      if (selectedTask) {
        setActiveTripTaskId(selectedTask?.id);
        const ele = document.getElementById(`${selectedTask?.id}`);
        if (ele) {
          ele.scrollIntoView({ behavior: "smooth", block: "center" });
          ele.classList.add("highlight-task");
        }
      }
    } catch (error) {
      console.log("Error while scrolling task-->", error);
    }
  });
  useEffect(() => {
    if (lastUpdatedTime && lastUpdatedTime > 0) {
      loadVehicleDetails();
    }
  }, [lastUpdatedTime]);
  const renderZones = (zones: IZone[]) => {
    return (
      <div className="vehicle-property temperature">
        <div className="mt-1">
          <span className="mr-1">
            <img src={thermostat}></img>
            {`${i18nInstance.t("TTM.followup.temperature")} ${separator} `}
          </span>
          <VehicleZones zones={formatZones(zones)} viewMode={ViewMode.CARD} />
        </div>
      </div>
    );
  };
  return (
    <>
      {selectedVehicle?.vehicle ? (
        <div className="vehicle-details d-flex flex-row flex-wrap ml-4">
          {selectedVehicle?.crew?.length > 0 && (
            <div className="vehicle-property tacho-status">
              <TachoStatus data={selectedVehicle?.crew} />
            </div>
          )}
          {selectedVehicle?.speed ? (
            <div className="vehicle-property speed" title={i18nInstance.t("TTM.followup.speed")}>
              <div className="mt-1">
                <span className="ic-wrapper">
                  <img src={speed}></img>
                </span>
                {selectedVehicle.speed} {MEASUREMENT_UNITS.SPEED}
              </div>
            </div>
          ) : (
            <></>
          )}
          {selectedVehicle?.mileage ? (
            <div
              className="vehicle-property mileage"
              title={i18nInstance.t("TTM.followup.mileage")}
            >
              <div className="mt-1">
                <span className="ic-wrapper">
                  <img src={mileage}></img>
                </span>
                {selectedVehicle.mileage} {MEASUREMENT_UNITS.DISTANCE}
              </div>
            </div>
          ) : (
            <></>
          )}

          {selectedVehicle?.crew && selectedVehicle.crew.length > 0 ? (
            <div
              className="vehicle-property crew-wrapper"
              title={i18nInstance.t("TTM.followup.drivingCrew")}
            >
              <VehicleCrew data={selectedVehicle.crew} />
            </div>
          ) : (
            <></>
          )}
          {selectedVehicle?.location?.address ? (
            <div
              className="vehicle-property d-flex flex-row"
              title={i18nInstance.t("TTM.followup.currentLocation")}
            >
              <span className="ic-wrapper">
                <img src={locationPin}></img>
              </span>
              <span className="address-block">
                <Address data={selectedVehicle.location.address} />
              </span>
            </div>
          ) : (
            <></>
          )}
          {selectedVehicle?.weight && (
            <div
              className="vehicle-property d-flex flex-row weight"
              title={i18nInstance.t("TTM.followup.weight")}
            >
              <div className="mt-1">
                <span className="ic-wrapper">
                  <img src={weight}></img>
                </span>
                {selectedVehicle.weight} {MEASUREMENT_UNITS.WEIGHT}
              </div>
            </div>
          )}
          {selectedVehicle?.reeferEngine ? (
            <OverlayTrigger 
              placement="auto"
              trigger={["hover", "click"]}
              delay={{ show: 300, hide: 0 }}
              overlay={selectedVehicle?.reeferEngine?.timestamp ? (
                <Tooltip id="vehicle-reefer-tooltip">
                  <span className="tooltip-info">
                    {`${i18nInstance.t("TTM.followup.lastUpdated")} ${separator} `}
                    <DateTime dateTime={selectedVehicle?.reeferEngine ?.timestamp} />
                  </span>
                </Tooltip>
                ) : <span/>}
              >
              <div className="vehicle-property vehicle-reefer">
                <div className="mt-1">
                  <span className="mr-1">
                    <Reefer className={`${selectedVehicle?.reeferEngine?.status.toLowerCase() === VEHICLE_REEFER_STATUS.ON ? "svg-reefer-on" : ""}`}/> 
                  </span>
                  {`${i18nInstance.t(`TTM.followup.reeferEngine`)} ${separator} `}
                  {getTranslatedValue("TTM.followup.", selectedVehicle?.reeferEngine?.status.toLowerCase())}
                </div>
              </div>
            </OverlayTrigger>
          ) : (
            <></>
          )}
          {selectedVehicle?.fuel && (
            <div
              className="vehicle-property d-flex flex-row fuel"
              title={i18nInstance.t("TTM.followup.fuelLevel")}
            >
              <div className="mt-1 d-flex flex-row">
                <span className="ic-wrapper">
                  <img src={fuel}></img>
                </span>
                <ProgressBar now={selectedVehicle.fuel} />
              </div>
            </div>
          )}
          {selectedVehicle?.zones && selectedVehicle?.zones?.length > 0 ? (
            renderZones(selectedVehicle?.zones)
          ) : (
            <></>
          )}
        </div>
      ) : null}
      {isLoading ? (
        <div className="vehicle-details-preloader">
          <Preloader />
        </div>
      ) : null}
      <>
        {vehicleTripTask ? (
          <div className={`${!isMobile ? "task-container m-3" : "m-3"}`}>
            {vehicleTripTask?.trips && vehicleTripTask.trips.length > 0 && (
              <TripView
                selectedTask={selectedTask}
                data={vehicleTripTask?.trips}
                vehicleId={vehicleTripTask.id}
                selectedRoute={selectedRoute}
                activeTripTaskId={activeTripTaskId}
                setSelectedRoute={setSelectedRoute}
                activeDescriptionIds={activeDescriptionIds}
                toggleTaskDescription={toggleTaskDescription}
                actionName={`${
                  props?.actionName ? `${props.actionName}_TRIP_TOGGLE_ACCORDION` : ""
                }`}
              />
            )}

            {vehicleTripTask?.tasks && vehicleTripTask.tasks.length > 0 && (
              <Accordion
                id={`tasks_${selectedVehicle?.id}`}
                title={`${vehicleTripTask?.tasks?.length} ${i18nInstance.t("TTM.followup.task", {
                  count: vehicleTripTask?.tasks?.length,
                })}`}
                isActive={
                  vehicleTripTask?.tasks?.find((t: ITask) => t.id === activeTripTaskId)
                    ? true
                    : false
                }
                selectedTask={selectedTask}
                tasks={vehicleTripTask.tasks}
                selectedRoute={selectedRoute}
                setSelectedRoute={setSelectedRoute}
                tripTaskStatus={status}
                actionName={`${
                  props?.actionName ? `${props.actionName}_TASK_TOGGLE_ACCORDION` : ""
                }`}
              >
                <TaskView
                  data={vehicleTripTask.tasks}
                  activeDescriptionIds={activeDescriptionIds}
                  toggleTaskDescription={toggleTaskDescription}
                />
              </Accordion>
            )}
          </div>
        ) : null}
      </>
      {selectedVehicle?.trailer ? (
        <TrailerDetails trailer={selectedVehicle?.trailer} status={selectedVehicle.vehicle.status}/>
      ) : (
        <></>
      )}

      {vehicleDetailsError ? (
        <div className="error-toast-message">
          <ToastWrapper
            onClose={() => setDetailsToast(defaultToastObj)}
            toastProps={detailsToast}
          />
        </div>
      ) : null}
    </>
  );
};
export default observer(VehicleDetailsView);
