import React, { useEffect, useState } from "react";
import "./TracesContainer.scss";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useEventSource } from "../../../../../hooks/useEventSource/useEventSource";
import TraceService from "../../../../../services/Trace.service";
import { ITrace } from "../../../../../models/TraceModel";
import { getEndTimeFromDate, getStartTimeFromDate } from "../../../../../common/utils";
import { DATE_DISPLAY_FORMAT, PATH, TOAST_STYLE } from "../../../../../common/constants";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import moment from "moment";
import ToastWrapper, {
  IToast,
  defaultToastObj,
} from "../../../molecules/ToastWrapper/ToastWrapper";
import { useTraceStore } from "../../../../../contexts/traces.context";
import { observer } from "mobx-react-lite";

const TracesContainer = () => {
  const params = useParams();
  const navigate = useNavigate();
  const traceStore = useTraceStore();
  const traceService = new TraceService();
  const [selectedTrace, setSelectedTrace] = useState<ITrace | null>(null);
  const [highlightedTrace, setHighlightedTrace] = useState<ITrace>();
  const [sourceURL, setSourceURL] = useState<string>("");
  const [terminalId, setTerminalId] = useState<string>("");
  const [queryDate, setQueryDate] = useState<string>("");
  const [resetTraces, setResetTraces] = useState<boolean>(false);
  const [toastObj, setToastObj] = useState<IToast>(defaultToastObj);

  const [source, data, isCompleted, error, consolidatedData] = useEventSource({
    src: { url: sourceURL },
  });

  const handleSelectedTrace = (trace: ITrace) => {
    setSelectedTrace(trace);
  };
  const handleHighlightedTrace = (trace: ITrace) => {
    setHighlightedTrace(trace);
  };

  useEffect(() => {
    error &&
      setToastObj({
        showToast: true,
        message: i18nInstance.t("TTM.followup.generic.error"),
        style: TOAST_STYLE.ERROR,
      });
  }, [error]);

  /**
   * UseEffect to abort/close the stream when user changes the unit while existing request in progress.
   */
  useEffect(() => {
    if ((params?.unitId !== terminalId || params?.date !== queryDate) && !isCompleted) {
      source?.close();
    }
  }, [params?.unitId, params?.date, isCompleted]);

  /**
   * UseEffect to define start and end time from the params.
   * If date is invalid, user will be navigated to /history path.
   * If unitId or date is changed, reset will be set to true.
   */
  useEffect(() => {
    try {
      if (
        params?.unitId &&
        params?.date &&
        moment(params?.date, DATE_DISPLAY_FORMAT, true)?.isValid() &&
        moment(params?.date, DATE_DISPLAY_FORMAT, true)?.isSameOrBefore(moment())
      ) {
        const traceTypes = traceStore?.traceFilter?.traceTypes;
        if (
          params?.unitId !== terminalId ||
          params?.date !== queryDate ||
          (traceTypes && traceTypes.length >= 0)
        ) {
          setResetTraces(true);
          setSelectedTrace(null);
          traceStore?.setTraceDetails(null);
        }
        setTerminalId(params?.unitId);
        setQueryDate(params?.date);
        traceStore?.setTraceFilter?.({
          ...traceStore.traceFilter,
          selectedDate: moment(params.date, DATE_DISPLAY_FORMAT, true),
          terminalId: params.unitId,
        });
        const startTime = getStartTimeFromDate(params.date);
        const endTime = getEndTimeFromDate(params.date);
        setSourceURL(
          traceService.getTraces(
            params.unitId,
            startTime,
            endTime,
            traceTypes?.map((i) => i.value)?.join(),
          ),
        );
        setToastObj({
          showToast: true,
          message: i18nInstance.t("TTM.followup.traces.loading"),
          style: TOAST_STYLE.PRIMARY,
          delay: 1250,
        });
      } else {
        navigate(PATH.TRACES);
      }
    } catch (error) {
      console.log("tracesContainer ~ error:", error);
    }
  }, [params?.unitId, params?.date, traceStore?.traceFilter?.traceTypes]);

  const handleOnResetTraces = () => {
    setResetTraces(false);
    setSourceURL("");
  };

  return (
    <>
      <Outlet
        context={{
          terminalId,
          queryDate,
          trace: data,
          resetTraces,
          selectedTrace,
          highlightedTrace,
          traceFilter: traceStore?.traceFilter,
          handleSelectedTrace,
          handleHighlightedTrace,
          onResetTraces: handleOnResetTraces,
          error,
          isDataLoaded: isCompleted,
        }}
      />
      <div className="traces-toast">
        <ToastWrapper
          toastProps={toastObj}
          onClose={() => setToastObj({ ...toastObj, showToast: false })}
        />
      </div>
    </>
  );
};

export default observer(TracesContainer);
