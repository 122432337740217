import React, { useContext, useEffect, useState } from "react";
import MapComponent from "../../../../global/organisms/MapComponent/MapComponent";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import ReactDOM from "react-dom";
import TraceDetails from "../../../../domain/Trace/organisms/TraceDetails/TraceDetails";
import { ReactComponent as TraceMarker } from "../../../../../assets/images/trace/trace_marker.svg";
import { coordinateToDegree, getAppConfig, sendMonitoringLogs } from "../../../../../common/utils";
import AuthContext from "@ttl/shared-react-library/src/auth/AuthContext";
import useMobileDetect from "../../../../../hooks/useMobileDetect/useMobileDetect";
import { useOutletContext } from "react-router-dom";
import { ITrace, ITraceOutletContext } from "../../../../../models/TraceModel";
import "./TracesMap.scss";
import { observer } from "mobx-react-lite";
import { useTraceStore } from "../../../../../contexts/traces.context";
import { find } from "lodash";
export interface ITracesMapProps {
  onMapLoad?: () => void;
}

const TracesMap = (props: ITracesMapProps) => {
  const appConfig = getAppConfig();
  const isMobile = useMobileDetect();
  const traceStore = useTraceStore();
  const { tokens } = useContext(AuthContext);
  const mapContext = useOutletContext<ITraceOutletContext>();
  const [selectedTrace, setSelectedTrace] = useState<ITrace>();
  const [traceMarker, setTraceMarker] = useState<TrimbleMaps.Marker[]>([]);
  const [isMapLoaded, setMapLoaded] = useState<boolean>(false);

  const getTraceMarkerElement = (trace: ITrace) => {
    const isHighlighted = mapContext?.highlightedTrace?.id == trace?.id;
    const ele: HTMLElement = document.createElement("div");
    ele.id = `marker_${trace?.id}`;
    ele.onclick = () => {
      mapContext?.handleSelectedTrace(trace);
      sendMonitoringLogs("HISTORY_MAP_TRACE_MARKER_SELECT");
    };
    ReactDOM.render(
      <TraceMarker className={isHighlighted ? "trace-marker-highlight" : "trace-marker"} />,
      ele,
    );
    return ele;
  };

  const handleMarkerPopupClose = () => {
    mapContext?.handleSelectedTrace(null);
  };
  const getTraceMarkers = (trace: ITrace) => {
    try {
      if (trace?.coordinate?.lat && trace.coordinate.lon) {
        const marker = new TrimbleMaps.Marker({
          draggable: false,
          element: getTraceMarkerElement(trace),
          rotation: trace?.heading,
        }).setLngLat([
          coordinateToDegree(trace.coordinate.lon),
          coordinateToDegree(trace.coordinate.lat),
        ]);
        if (!isMobile) {
          const popup = new TrimbleMaps.Popup();
          const htmlEle = document.createElement("div");
          ReactDOM.render(
            <TraceDetails
              trace={trace}
              traceType={find(traceStore?.traceTypes, { traceType: trace?.type })?.name}
              showTimestamp={true}
            />,
            htmlEle,
          );
          popup.setDOMContent(htmlEle);
          marker.setPopup(popup);
        }
        return marker;
      }
    } catch (error) {
      console.log("TracesMap ~ getMarkers ~ error:", error);
    }
  };

  const handleOnMapLoad = () => {
    setMapLoaded(true);
    props?.onMapLoad?.();
  };

  useEffect(() => {
    if (mapContext?.trace) {
      const marker = getTraceMarkers(mapContext.trace);
      if (marker) {
        if (!isMapLoaded) {
          setTraceMarker([...traceMarker, marker]);
        } else {
          setTraceMarker([marker]);
        }
      }
    }
  }, [mapContext?.trace]);

  useEffect(() => {
    mapContext?.resetTraces && setTraceMarker([]);
  }, [mapContext?.resetTraces]);

  useEffect(() => {
    setSelectedTrace(mapContext?.selectedTrace);
  }, [mapContext?.selectedTrace]);

  return (
    <>
      <MapComponent
        config={{
          ...appConfig.map,
          locale: tokens?.profile
            ?.language as (typeof TrimbleMaps.Common.Language)[keyof typeof TrimbleMaps.Common.Language],
          isMobile: isMobile,
        }}
        marker={isMapLoaded ? traceMarker : []}
        resetMap={mapContext?.resetTraces}
        onResetMap={mapContext?.onResetTraces}
        onMapLoad={handleOnMapLoad}
        selectedMarkerId={selectedTrace?.id}
        coordinates={selectedTrace?.coordinate}
        highlightMarkerId={mapContext?.highlightedTrace?.id}
        handleMarkerPopupClose={handleMarkerPopupClose}
      />
    </>
  );
};

export default observer(TracesMap);
