import React from "react";
import "./TrailerDetails.scss";
import { IStatus, ITrailer } from "../../../../../models/VehicleModel";
import TitleView from "../../../../molecules/TitleView/TitleView";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { MEASUREMENT_UNITS, VEHICLE_REEFER_STATUS } from "../../../../../common/constants";
import { getDuration } from "../../../../../common/duration";
import { formatZones } from "../../../../../common/utils";
import VehicleZones, { ViewMode } from "../../molecules/VehicleZones/VehicleZones";
import mileage from "../../../../../assets/images/mileage.svg";
import tether from "../../../../../assets/images/tether.svg";
import weight from "../../../../../assets/images/weight.svg";
import thermostat from "../../../../../assets/images/thermostat.svg";

export interface ITrailerDetailsProps {
  trailer: ITrailer;
  status: IStatus;
}

const TrailerDetails = (props: ITrailerDetailsProps) => {
  const trailer = props.trailer;
  const separator = ":"
  return (
    trailer && (
        <>
          <div className="vehicle-title-block trailer">
            {trailer?.name ? (
              <TitleView
                type={trailer.type}
                title={trailer.name}
                status={props.status}
              />
            ) : null}
          </div>
          <div className="vehicle-details d-flex flex-row flex-wrap ml-4">
            {trailer?.mileage ? (
              <div className="vehicle-property trailer-mileage">
                <div className="mt-1">
                  <span className="mr-1">
                    <img src={mileage}></img>
                  </span>
                  {`${i18nInstance.t("TTM.followup.mileage")} ${separator} ${trailer.mileage}`}
                  {MEASUREMENT_UNITS.MILEAGE}
                </div>
              </div>
            ) : (
              <></>
            )}
            {trailer?.tetherTime ? (
              <div className="vehicle-property trailer-tethertime">
                <div className="mt-1">
                  <span className="mr-1">
                    <img src={tether}></img>
                  </span>
                  {`${i18nInstance.t("TTM.followup.tetheringTime")} ${separator} ${getDuration(trailer.tetherTime)}`}
                </div>
              </div>
            ) : (
              <></>
            )}
            {trailer?.weight ? (
              <div className="vehicle-property trailer-weight">
                <div className="mt-1">
                  <span className="mr-1">
                    <img src={weight}></img>
                  </span>
                  {`${i18nInstance.t("TTM.followup.weight")} ${separator} ${trailer?.weight}`}
                  {MEASUREMENT_UNITS.WEIGHT}
                </div>
              </div>
            ) : (
              <></>
            )}
            {trailer?.zones && trailer.zones?.length > 0 ? (
              <div className="vehicle-property temperature">
                <div className="mt-1">
                    <span className="mr-1">
                    <img src={thermostat}></img>
                    {`${i18nInstance.t("TTM.followup.temperature")} ${separator} `}
                    </span>
                    <VehicleZones zones={formatZones(trailer?.zones)} viewMode={ViewMode.CARD} />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
    )
  )
}   
export default TrailerDetails;